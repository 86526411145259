import React from "react";
import { Outlet } from "react-router-dom";
import HomeNavbar from "./HomeNavbar";

interface RootProps {
}

const navHeight = 3;    // in REM
export default class Root extends React.Component<RootProps> {
    constructor(props: RootProps) {
        super(props);
    }
    render() {
        return (
            <div className="">
                <HomeNavbar height={navHeight}/>
                <div className="position-relative" style={{marginTop: navHeight + "rem"}}>
                    <Outlet />
                </div>
            </div>
        );
    }
}

export {navHeight}