
import React, { useContext } from 'react'
import { HashLink } from 'react-router-hash-link';
import { LoginContext } from './SecretRoot';

interface NavBarProps {
    height: number;
    loggedIn: boolean | undefined;
}

interface NavBarState {
    scale: number,
    roundedImage: boolean,
    // loggedIn: boolean
}

export default function SecretNavbar(props: {height: number}) {
    const context = useContext(LoginContext);
    return <SecretNavbarClass height={props.height} loggedIn={context.loggedIn}/>
}

class SecretNavbarClass extends React.Component<NavBarProps, NavBarState> {
    height: number;
    imgHeight: number;
    // declare context: React.ContextType<typeof LoginContext>;
    // static contextType = LoginContext;

    constructor(props: NavBarProps) {
        super(props);
        this.state = {
            scale: 1,
            roundedImage: true,
            // loggedIn: false
        }
        this.height = (this.props.height);
        this.imgHeight = (this.props.height * .8);
    }

    /**
     * Create a link object for the sidebar
     * @param text the text displayed for this link
     * @param path the path this link leads to
     */
    link(text: string, path: string) {
        let innerHTML = <div style={{ color: 'inherit' }}>
            {text}
        </div>

        let outerClassName = "text-light bg-hover-primary-secret text-hover-dark text-decoration-none px-4 d-flex align-items-center";

        return (
            <HashLink className={outerClassName} to={'/' + path} >{innerHTML}</HashLink>
        )
    }

    home() {
        let onMouseEnter = () => this.setState({ roundedImage: false });
        let onMouseLeave = () => this.setState({ roundedImage: true });
        let outerClassName = "px-2 d-flex align-items-center";

        let innerHTML = <img
            style={{ width: this.imgHeight * this.state.scale + "rem" }}
            className={"img-transition shadow-sm " + (this.state.roundedImage ? "rounded-circle" : "rounded")}
            src="faviconLG.jpg"
            alt="" />;

        // Prevents hashlink from not scrolling to very top due to navbar
        const scrollWithOffset = (el: any) => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -2000;  // just picking a large number
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }

        return (
            <HashLink
                to="/secret"
                scroll={el => scrollWithOffset(el)}
                className={outerClassName}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                {innerHTML}
            </HashLink >
        )
    }

    // async auth() {
    //     this.setState({ loggedIn: await isAuthenticated() });
    // }

    render() {
        return (
            <div
                onMouseEnter={() => this.setState({ scale: 1.2 })}
                onMouseLeave={() => this.setState({ scale: 1 })}
                style={{ height: this.height * this.state.scale + "rem" }} className='bg-dark-secret my-border-primary-secret border-down border-width-3 position-fixed fixed-top position-fixed d-flex shadow size-transition'>
                <nav className='container-lg d-flex'>
                    {this.home()}
                    {this.props.loggedIn ? this.link("Logged In", "secret/login") : this.link("Sign In", "secret/login")}
                    {this.link("Home", "")}
                </nav>
            </div>
        );
    }
}