import { Link } from "react-router-dom";
import { useRouteError } from "react-router-dom";

export default function Error(props: { home: string }) {
    const error: any = useRouteError();   // for some reason useRouteError does not specify a return type :(

    return (
        <div className="text-center position-relative d-flex justify-content-center"
            id="error-page">
            <div className="bg-dark rounded p-5 m-5">
                <h1>{error.status}</h1>
                <p><em className="text-secondary">{error.statusText || error.message}</em></p>

                {error.status == 404 && <p>The page you are looking for may not exist.</p>}
                <Link to={props.home} className="btn btn-success">Return Home</Link>
            </div>
        </div>
    );
}