import React from "react";


interface Props {
    items: JSX.Element[],
    width: number
}

interface State {
    index: number,
    scroll: number
}

export default class Slideshow extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            index: 0,
            scroll: 0
        }
    }

    increment() {
        let curr = this.state.index;
        let currScroll = this.state.scroll;
        let next = curr + 1;
        let scroll = currScroll - 1;
        if (curr == this.props.items.length - 1) {
            next = 0;
        }
        if (scroll < -(this.props.items.length - 1)) {
            scroll = (0);
        }
        this.setState({ index: next, scroll: scroll })
    }

    decrement() {
        let curr = this.state.index;
        let currScroll = this.state.scroll;
        let next = curr - 1;
        let scroll = currScroll + 1;
        if (curr == 0) {
            next = this.props.items.length - 1;
        }
        if (scroll > 0) {
            scroll = -(this.props.items.length - 1);
        }
        this.setState({ index: next, scroll: scroll })
    }

    elements() {
        let elements: JSX.Element[] = [];
        const containerWidth = this.props.width;
        const width = this.props.width;
        this.props.items.forEach((item, index) => {
            let offset = this.state.scroll * containerWidth;

            elements.push(
                <div key={index} style={{ transform: `translate(${offset}px)`, minWidth: width + "px", width: width + "px" }} className="slide-item position-relative">
                    {item}
                </div>
            )
        });

        return (
            <div style={{ width: containerWidth + "px" }} className="py-3 bg-dark border-radius-4 my-shadow position-relative overflow-hidden d-flex align-items-center">
                {elements}
                <div style={{left: 0, lineHeight: "4rem", height:"4rem", width: "2rem"}} className="opacity-hover bg-dark text-center rounded-end text-hover-primary position-absolute align-self-center h1 user-select-none" onClick={() => this.decrement()}>&#10094;</div>
                <div style={{right: 0, lineHeight: "4rem", height:"4rem", width: "2rem"}} className="opacity-hover bg-dark text-center rounded-start text-hover-primary position-absolute align-self-center h1 user-select-none" onClick={() => this.increment()}>&#10095;</div>
            </div>
        );
    }

    render(): React.ReactNode {
        return (<div className="position-relative d-flex justify-content-center">
            {this.elements()}
        </div>
        )
    }
}