import React, { useContext } from "react";
import { POST } from "../tools/fetch";
import requestUrl from "../tools/requestUrl";
import ServerRoutes from "../../shared/ServerRoutes";
import { Link } from "react-router-dom";
import { LoginContext } from "./SecretRoot";


interface Props {
    loggedIn: boolean;
}

interface State {
    password: string,
    error: boolean | null,
}

export default function Login() {
    const context = useContext(LoginContext);
    return <LoginClass loggedIn={context.loggedIn} />
}

class LoginClass extends React.Component<Props, State> {

    // declare context: React.ContextType<typeof LoginContext>
    constructor(props: Props) {
        super(props)
        this.state = {
            password: "",
            error: null,
            // loggedIn: false
        }
    }

    // componentDidMount() {
    //     this.auth();
    // }

    async signIn() {
        let response = await POST(requestUrl(ServerRoutes.PASSWORD_AUTH), { password: this.state.password });

        if (response.status != 200) {
            this.setState({ error: true });
            this.setState({ password: "" })
            return;
        }
        this.setState({ error: false });
    }

    // async auth() {
    //     console.log("Running auth token check");
    //     let loggedIn = await isAuthenticated();
    //     if (loggedIn) {
    //         this.setState({ loggedIn: true })
    //     }
    // }

    logIn() {
        return <>
            <p className="text-secondary"><em>Enter Password</em></p>
            <input onChange={(e) => this.setState({ password: e.target.value })} className="form-control px-2" type="password" value={this.state.password} />
            <button onClick={() => this.signIn()} className="mt-2 w-100 px-3 btn btn-success">Sign In</button>
            {this.state.error && <small className="fst-italic text-danger m-0 p-0">Invalid Credentials</small>}
            {this.state.error != null && !this.state.error && <small className="fst-italic text-success m-0 p-0">Authenticated</small>}
        </>
    }

    alreadyLoggedIn() {
        return <>
            <p className="text-secondary"><em>You are already logged in</em></p>
            <p className="my-3"><small className="fst-italic text-success m-0 p-0">Authenticated via token</small></p>
            <Link className="btn btn-success" to="/secret">Back to Secret</Link>
        </>
    }

    render() {
        return <div className="text-center position-relative d-flex justify-content-center">
            <div className="bg-dark rounded py-5 px-3 p-sm-5 my-5 mw-100">
                <h1>Secret Login</h1>
                {this.props.loggedIn ? this.alreadyLoggedIn() : this.logIn()}
            </div>
        </div>
    }
}