import React, { useContext } from "react";
import { LoginContext } from "./SecretRoot";
import { Navigate, useOutletContext } from "react-router-dom";
import { isAuthenticated } from "../tools/auth";
import { GET } from "../tools/fetch";
import requestUrl from "../tools/requestUrl";
import ServerRoutes from "../../shared/ServerRoutes";
import { Link } from "react-router-dom";

export default function SecretHomePage() {
    const context = useContext(LoginContext);
    return <SecretHomePageClass loggedIn={context.loggedIn}/>
}

interface Props {
    loggedIn: boolean;
}

class SecretHomePageClass extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        if(!this.props.loggedIn) {
            return <div className="text-danger text-center">You are not logged in.</div>
        }
        return <>
            <div className="container pt-3 text-light">
                <h1 className="text-primary-secret text-center">Welcome Traveler</h1>
                <nav>
                    <li><Link to={`${ServerRoutes.SECRET("valorant-lineups")}`}>Valorant Lineups</Link></li>
                </nav>
            </div>
        </>
    }
}