import Project from "./Project";

/**
 * ServerRoutes contains the string name for all server routes.
 */
const ServerRoutes = {
    ANY: "*",
    PROJECT: (project: string) => "/projects/" + project,
    PASSWORD_AUTH: "/secret/login/",
    TOKEN_AUTH: "/secret/token-login/",
    SECRET: (project: string) => "/secret/projects/" + project,
    VALORANT: "/valorant-lineups"
}

export default ServerRoutes;