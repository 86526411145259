import React from "react";
import { useParams } from "react-router-dom";
import { navHeight } from "./Root";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faX } from "@fortawesome/free-solid-svg-icons";


interface State {
    showOpenFull: boolean;
}

export default function ProjectPreview(props: {path: string}) {
    const params = useParams();
    if (!params.project) {
        throw new Response("Invalid Parameter", { status: 400 });
    }
    return <ProjectPreviewClass path={props.path} project={params.project} />
}

interface Props {
    project: string,
    path: string,
}

class ProjectPreviewClass extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showOpenFull: true
        }
    }

    overlay() {
        let innerHTML = <div style={{ color: 'inherit' }} className="">
            <div onClick={(e) => {
                e.preventDefault();
                this.setState({ showOpenFull: false });
            }} 
            style={{ right: -10, top: -20, fontSize: "0.65rem" }} className="text-muter position-absolute text-hover-light dark rounded-circle p-3"><FontAwesomeIcon icon={faX} /></div>
            <div className="p-2 px-4">
                {"Open full project"}
            </div>
        </div>

        let outerClassName = "position-absolute text-light bg-dark rounded mt-2 me-5 my-shadow bg-hover-primary-dark text-hover-dark text-decoration-none d-flex align-items-center";

        return <a style={{ right: 0, top: 0 }} className={outerClassName} href={`/${this.props.path}/${this.props.project}/index.html`}>{innerHTML}</a>
    }

    render() {
        return <div className="">
            {this.state.showOpenFull && this.overlay()}
            <iframe style={{ height: `calc(100vh - ${navHeight + "rem"})`, display: "block" }} className="w-100" src={`${this.props.path}/${this.props.project}`}></iframe>
        </div>
    }
}