import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faGithub, faArtstation, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Slideshow from "./Slideshow";
import { navHeight } from "./Root";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Project from "../../shared/Project";
import { Link } from "react-router-dom";

interface State {
    width: number;
    height: number;
}

export default class HomePage extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    project(title: string, href: string, imgSrc: string, description: string) {
        return <li key={title} className="px-3 m-0">
            <Link style={{ textDecoration: "none" }} target='' to={href} className="">
                <h3 className="p-0 ubuntu text-center">{title}</h3>
                <div className="text-light d-flex flex-column flex-lg-row my-shadow bg-hover-primary text-hover-dark bg-muted p-2 border-radius-2 my-border-hover-primary-darker my-border-muter border-all border-width-2 my-border-hover-primary-darker">
                    <img className="project-img rounded my-shadow" src={imgSrc} alt="" />
                    <p style={{ color: "inherit" }} className="px-2 project-description">{description}</p>
                </div>
            </Link>
        </li>
    }

    contactLink(icon: IconDefinition, href: string, text: string) {
        return <p className="mx-lg-3 ">
            <FontAwesomeIcon className="text-info" icon={icon} /> <a className="isLink text-info" target="_blank" href={href}>
                {text}
            </a>
        </p>
    }

    projectLink(project: Project) {
        return "preview/" + project;
    }

    render() {
        // TODO: Add the following to this list: Soul Dash, Deeper Voyage, Tricks of the Trade, AI Project 2 (Neural Network), AI Project 1 (A*), Data Privacy Extension
        let projects = [
            this.project("Pathfinding Algorithm Visualizer", this.projectLink(Project.PATHFINDING), "images/pathfinding.png",
                "A pathfinding algorithm visualizer. Draw a maze, select a start and end location, select an algorithm, and watch how the algorithm finds the pathway from start to finish. Supports depth-first, breadth-first, and A* searches."),
            this.project("Custom Wordle", this.projectLink(Project.WORDLE), "images/wordle.png",
                "Customizable Wordle, has daily wordles, seeded random words, and n-sized words. You can send a \"challenge link\" with a predetermined solution and number of allowed tries to challenge friends."),
            this.project("Chess", this.projectLink(Project.CHESS), "images/chessgame.png",
                "A work-in-progress chess game website. The idea is to make a highly customizeable chess interface for playing regularly or making your own custom modes."),
            this.project("SimBox", this.projectLink(Project.SIMBOX), "images/simbox.png",
                "SimBox is a website created in my software engineering class with a small group of other students. It is a 2D physics simulation sandbox which implements the plank.js library for physics."),
            this.project("Minesweeper", this.projectLink(Project.MINESWEEPER), "images/minesweeper.png",
                "A simple Minesweeper clone, with a timer and control over rows, columns, and number of bombs."),
            this.project("BounceGame", this.projectLink(Project.BOUNCE_GAME), "images/bounce.png",
                "A work-in-progress project. Currently it is only an experiment with hand-coded basic rigid body physics, and game engines, but I do intend to turn it into a more fleshed out and complete project soon.")
        ];
        return (
            <div className="d-flex align-items-center flex-column">
                {/* SPLASH */}
                <div id="splash" style={{ height: `calc(100vh - ${navHeight + "rem"})` }} className="position-relative w-100 text-center intro">
                    <div className="fall-in">
                        <h1 className="intro-name ubuntu">Phineas Ziegler</h1>
                        <p className="text-center text-primary intro-subtitle" style={{ fontFamily: "monospace" }}>Software Engineer</p>
                    </div>
                </div>

                {/* ABOUT */}
                <div id="about" style={{ minHeight: `100vh` }} className="py-5 bg-secondary-dark w-100">
                    <div className="p-3 container">
                        <h1 className="text-center text-primary">About me</h1>
                        <hr className="text-light" />
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center px-lg-5">
                            <img className="m-4 rounded-circle" style={{ width: "300px" }} src="images/me.jpg" alt="" />
                            <p className="description">
                                I graduated from Case Western Reserve University with a BS in Computer Science on the software engineering track. As of June of 2023, I am working full-time at <a href="https://www.mimsoftware.com/">MIM Software Inc.</a> as a software engineer. My skills include Java, Node, TypeScript, SQL, React, C#, and Unity. I am most experienced with full-stack development, but also have experience making games from classes and personal projects.
                                <span className="d-flex justify-content-end">
                                    <em className="text-muted">(last update 6/21/2023)</em>
                                </span>
                            </p>
                        </div>
                        <br />
                        <h2 className="text-center text-secondary">What is This?</h2>
                        <hr className="text-light" />
                        <p className="">
                            This is my personal website for all things Phineas and Phineas adjacent. I intend to use it as a resume/portfolio, and also to host any applications that I work on. One server and one domain for all my current and future projects.
                        </p>
                    </div>
                </div>

                {/* WORK */}
                <div id="work" style={{ minHeight: "100vh" }} className="py-5 bg-tertiary w-100">
                    <div className="p-3 text-center">
                        <h1 className="text-primary ubuntu">Work</h1>
                        <em className="text-secondary">A collection of some of my personal projects and schoolwork.</em>
                        <hr className="w-100 text-light" />
                    </div>
                    <Slideshow width={this.state.width > 1300 ? 1200 : this.state.width > 991 ? this.state.width * .8 : this.state.width - 20} items={projects} />
                </div>


                <div style={{ minHeight: "100vh" }} className="position-relative bg-dark w-100 d-flex flex-column align-items-center">
                    {/* CONTACT INFO */}
                    <div id="contact" className="mt-5 p-lg-5 w-100">
                        <div className="mt-5 p-lg-3 w-100 container d-flex flex-column align-items-center">
                            <h1 className="text-primary text-center">Contact Info</h1>
                            <div className="text-center"><em className="w-100 text-secondary">Please reach out with any questions you may have, or just to say hi. Lets connect! </em></div>
                            <hr className="w-100 text-light" />
                            <div className="px-lg-5 w-lg-50">
                                {this.contactLink(faEnvelope, "mailto: phinziegler@gmail.com", "phinziegler@gmail.com")}
                                {this.contactLink(faLinkedin, "https://www.linkedin.com/in/phineas-ziegler/", "LinkedIn")}
                                {this.contactLink(faGithub, "https://github.com/phinziegler", "GitHub")}
                                {this.contactLink(faArtstation, "https://www.artstation.com/", "Artstation")}
                                {this.contactLink(faInstagram, "https://www.instagram.com/phinz.3d/?hl=en", "Instagram")}
                            </div>
                        </div>
                    </div>

                    {/* FOOTER */}
                    <div style={{ bottom: 0 }} className="position-absolute mt-5 my-text-lighten my-bg-darken rounded-top px-3">
                        &copy; Phineas Ziegler, 2023
                    </div>
                </div>
            </div>
        );
    }
}