import React, { ContextType, createContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import SecretNavbar from "./SecretNavbar";
import { isAuthenticated } from "../tools/auth";

interface RootProps {
}

export interface State {
    loggedIn: boolean
}

const navHeight = 3;    // in REM
export default class SecretRoot extends React.Component<RootProps, State> {
    constructor(props: RootProps) {
        super(props);
        this.state = {
            loggedIn: false
        }
    }

    setLoggedIn(loggedIn: boolean) {
        this.setState({ loggedIn: loggedIn })
    }

    async componentDidMount() {
        this.setState({ loggedIn: await isAuthenticated() });
    }

    render() {
        return (
            <LoginContext.Provider value={{ loggedIn: this.state.loggedIn }}>
                <div className="">
                    <SecretNavbar height={navHeight} />
                    <div className="position-relative" style={{ marginTop: navHeight + "rem" }}>
                        <Outlet />
                    </div>
                </div>
            </LoginContext.Provider>
        );
    }
}

export const LoginContext = createContext<State>({
    loggedIn: false
});

export { navHeight }