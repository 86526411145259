import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import '../../client/styles/css/style.css';
import Root from './Root';
import Error from './Error';
import HomePage from './HomePage';
import ProjectPreview from './ProjectPreview';
import Login from './Login';
import SecretRoot from './SecretRoot';
import SecretHomePage from './SecretHomePage';


export default function RouteManager(props: {}) {



    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root/>,
            errorElement: <Error home="/"/>,
            children: [
                {
                    path: "",
                    element: <HomePage />
                }, {
                    path: "preview/:project",
                    element: <ProjectPreview path="projects"/>
                }
            ]
        }, {
            path: "secret",
            element: <SecretRoot />,
            errorElement: <Error home='/secret'/>,
            children: [
                {
                    path: "",
                    element: <SecretHomePage />
                }, {
                    path: "login",
                    element: <Login />
                }, {
                    path: "projects/:project",
                    element: <ProjectPreview path="secret/projects" />
                }
            ]
        }
    ]);
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
}