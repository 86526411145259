import ServerRoutes from "../../shared/ServerRoutes";
import { GET, POST } from "./fetch";
import requestUrl from "./requestUrl";

export async function isAuthenticated() {
    let response = await POST(requestUrl(ServerRoutes.TOKEN_AUTH), {});
    if(response.status != 200) {
        return false;
    }
    return true;
}