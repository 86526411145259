import ReactDOM from 'react-dom/client';
import RouteManager from "./client/components/RouteManager";

function start() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <RouteManager />
  );
}
start();